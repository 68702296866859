<template>
	<div class="loginBox">
		<div class="login">
			<p class="login-left" />
			<div class="login-box">
				<ul class="tabs">
					<li
						:key="item.key"
						v-for="(item, index) in tabsList"
						@click="setActiveName(item.key)"
						:style="index === 0 ? 'margin-right:40px' : ''"
						:class="{ highlight: activeName === item.key, hastwo: tabsList.length !== 1 }"
					>
						{{ item.val }}
					</li>
				</ul>

				<el-form class="form" ref="form" :rules="rules" :model="formData">
					<el-form-item prop="account" ref="account">
						<el-input
							:placeholder="'请输入' + placeholderTxt"
							v-model="formData.account"
							@keyup.enter.native="onSubmit"
							key="account"
							clearable
						></el-input>
					</el-form-item>
					<el-form-item prop="password" v-if="activeName === 'password'" ref="password">
						<el-input :type="passw" placeholder="请输入登录密码" v-model="formData.password" key="password" clearable></el-input>
					</el-form-item>
					<el-form-item v-else prop="authCode" ref="authCode">
						<el-input placeholder="请输入验证码" v-model="formData.authCode" @keyup.enter.native="onSubmit">
							<el-button slot="append" @click="postAccountGetAuthCode" :style="sendCodeBtnStyle" round>{{
								0 >= Countdown ? '发送验证码' : Countdown + 's'
							}}</el-button>
						</el-input>
					</el-form-item>
				</el-form>
				<div class="btn">
					<el-button class="loginbtn" @click="onSubmit">登录</el-button>
					<div v-show="activeName === 'password'" class="forgetpsd">
						<router-link class="fr" :to="{ path: '/forget' }" style="color: #5f6368">忘记密码？</router-link>
					</div>
				</div>
				<div class="read">
					<el-checkbox v-model="hasreaded">我已阅读并同意</el-checkbox>
					<span class="agreement" @click="goPage('service-agreement')">《用户协议》</span>
					<span style="color: #666">及</span>
					<span class="agreement" @click="goPage('privacy')">《法律声明及隐私政策》</span>
				</div>
                <!-- 分销商机构学员注册 -->
				<div class="footerTip" v-if="canRegister && !isrenshe">
					<span class="tip">还没账号？ </span>
					<a href="javascript:;" class="view" @click="toRegister"> 立即注册 ></a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/* eslint-disable */
import $ from 'jquery'
import md5 from 'js-md5'
import config from '@/config/url'
import { isPhone, isEmail, isIdentitycard } from '@/utils/validator'
import isrenshe from '@/utils/judgeDomain'
export default {
	name: 'login',
	data() {
		const accountValidator = (rule, value, callback) => {
			if (isPhone.test(value) || isEmail.test(value) || isIdentitycard.test(value)) {
				callback()
			} else {
				callback(new Error('请输入' + this.placeholderTxt))
			}
		}
		return {
			tabsList: [
				{ key: 'password', val: '密码登录' },
				{ key: 'Verification', val: '验证码登录' },
			],
			activeName: 'password',
			passw: 'password',
			CountdownInterval: null,
			Countdown: 0,
			smallImg: '',
			rules: {
				account: [
					{
						validator: accountValidator.bind(this),
						trigger: 'blur',
					},
				],
				password: [{ required: true, message: '请输入登录密码', trigger: 'blur' }],
				authCode: [
					{ required: true, message: '请输入验证码', trigger: 'blur' },
					{ min: 6, max: 6, message: '请输入六位验证码', trigger: 'blur' },
				],
			},
			formData: {
				account: null,
				password: null,
				authCode: null,
				adminFlag: null, // 1管理员
			},
			bigImg: '',
			loginMethodList: ['1', '2', '3', '4', '5'], //1,3验证码，1手机号3邮箱，           2，4，5密码 ，2手机号，4邮箱， 5身份证
			logintxt: '手机号码/邮箱/身份证号码',
			canRegister: false,
			hasreaded: false,
            isrenshe: true, //区分当前域名是人社租户、人社子机构
            branchId: '', // 人社子机构id
		}
	},
	created() {
		this.isrenshe = isrenshe
		const saasId = localStorage.getItem('saasId')
		sessionStorage.clear()
		localStorage.clear()
		localStorage.setItem('saasId', saasId)
        this.configuration(config.confUrl)
	},
	computed: {
		placeholderTxt() {
			if (this.tabsList.length == 1 && this.activeName === 'Verification' && this.logintxt.includes('身份证')) {
				let index = this.logintxt.lastIndexOf('/')
				return this.logintxt.slice(0, index)
			} else if (this.tabsList.length == 2 && this.activeName === 'password') {
				let strToArr = this.logintxt.split('/')
				if (!this.loginMethodList.includes('2') && this.logintxt.includes('手机号码')) {
					let index = strToArr.indexOf('手机号码')
					strToArr.splice(index, 1)
				}
				if (!this.loginMethodList.includes('4') && this.logintxt.includes('邮箱')) {
					let index = strToArr.indexOf('邮箱')
					strToArr.splice(index, 1)
				}
				return strToArr.join('/')
			} else if (this.tabsList.length == 2 && this.activeName === 'Verification') {
				let strToArr = this.logintxt.split('/')
				let ind = strToArr.indexOf('身份证号码')
				if (ind != -1) {
					strToArr.splice(ind, 1)
				}
				if (!this.loginMethodList.includes('1') && this.logintxt.includes('手机号码')) {
					let index = strToArr.indexOf('手机号码')
					strToArr.splice(index, 1)
				}
				if (!this.loginMethodList.includes('3') && this.logintxt.includes('邮箱')) {
					let index = strToArr.indexOf('邮箱')
					strToArr.splice(index, 1)
				}
				return strToArr.join('/')
			} else {
				return this.logintxt
			}
		},
		sendCodeBtnStyle() {
			let background = '#316fff'
			return {
				background: 0 >= this.Countdown ? background : '#BFC8D9',
				border: 'none',
			}
        },
        saasId(){
            return this.$store.state.dynamic.pageSetData.solutionInfo.rid
        }
    },
	methods: {
		//   隐私协议跳转
		goPage(url) {
			window.open(`${window.location.origin}/${url}`)
		},
		handleLoginTxt() {
			let result = '手机号码/邮箱/身份证号码'
			if (!this.loginMethodList.includes('1') && !this.loginMethodList.includes('3')) {
				//密码
				result = this.getMethodTxt()
				this.tabsList = [{ key: 'password', val: '密码登录' }]
			} else if (!this.loginMethodList.includes('2') && !this.loginMethodList.includes('4') && !this.loginMethodList.includes('5')) {
				//验证码
				result = this.getMethodTxt()
				this.tabsList = [{ key: 'Verification', val: '验证码登录' }]
				this.activeName = 'Verification'
			} else {
				//验证码+密码
				result = this.getMethodTxt()
				this.tabsList = [
					{ key: 'password', val: '密码登录' },
					{ key: 'Verification', val: '验证码登录' },
				]
			}
			this.logintxt = result
		},
		getMethodTxt() {
			let hasphone,
				hasemail,
				hasidentifycard = ''
			if (this.loginMethodList.includes('1') || this.loginMethodList.includes('2')) hasphone = '手机号码'
			if (this.loginMethodList.includes('3') || this.loginMethodList.includes('4')) hasemail = '邮箱'
			if (this.loginMethodList.includes('5')) hasidentifycard = '身份证号码'
			let arr = [hasphone, hasemail, hasidentifycard].filter((item) => {
				return !!item
			})
			return arr.join('/')
		},
		setActiveName(val) {
			if (val === this.activeName) return
			this.activeName = val
			this.$refs.account.clearValidate()
			if (this.activeName === 'password') {
				this.$refs.authCode.clearValidate()
			} else {
				this.$refs.password.clearValidate()
			}
		},
		configuration(url) {
			this.$api.configure.configurationNew(url).then((res) => {
				if (res.data) {
					//当前机构id
					this.branchId = res.data.branchId
					localStorage.setItem('domain', JSON.stringify(res.data.domain) ? JSON.stringify(res.data.domain) : '')
					localStorage.setItem('configurationArr', JSON.stringify(res.data.frontendConfig))
                    localStorage.setItem('branchId', res.data.branchId || '')
                    this.$store.commit('setTheme', res.data.theme)
					this.initConfig()
				}
			})
		},
		initConfig() {
			/*
                手机号+验证码 is_allow_telephone_verification_code_login 1
                手机号+密码   is_allow_telephone_password_login 2
                邮箱+验证码   is_allow_email_verification_code_login 3
                邮箱+密码     is_allow_email_password_login 4
                身份证+密码   is_allow_id_number_password_login 5
                注册方式:手机号+验证码  is_allow_telephone_register
                注册方式:邮箱+验证码    is_allow_email_register
            */
			let configurationArr = JSON.parse(localStorage.getItem('configurationArr'))
			if (configurationArr.length > 0) {
				configurationArr.forEach((item) => {
					switch (item.key) {
						case 'custom_login_background_img':
							this.bigImg = item.value
							break
						case 'custom_login_img':
							this.smallImg = item.value
							break
						case 'is_open_register':
							this.canRegister = item.value === 'true' ? true : false
							break
						case 'is_allow_telephone_verification_code_login':
							if (item.value === 'false') {
								let index = this.loginMethodList.indexOf('1')
								this.loginMethodList.splice(index, 1)
							}
							break
						case 'is_allow_telephone_password_login':
							if (item.value === 'false') {
								let index = this.loginMethodList.indexOf('2')
								this.loginMethodList.splice(index, 1)
							}
							break
						case 'is_allow_email_verification_code_login':
							if (item.value === 'false') {
								let index = this.loginMethodList.indexOf('3')
								this.loginMethodList.splice(index, 1)
							}
							break
						case 'is_allow_email_password_login':
							if (item.value === 'false') {
								let index = this.loginMethodList.indexOf('4')
								this.loginMethodList.splice(index, 1)
							}
							break
						case 'is_allow_id_number_password_login':
							if (item.value === 'false') {
								let index = this.loginMethodList.indexOf('5')
								this.loginMethodList.splice(index, 1)
							}
							break
						default:
							break
					}
				})

				const imgsrc1 = 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/beijin.png'
				const imgsrc2 = 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/login/rectangle.png'
				this.$nextTick(() => {
					// 防止背景图闪现两次
					$('.loginBox').css('background-image', `url(${this.bigImg ? this.bigImg : imgsrc1})`)
					$('.login-left').css('background-image', `url(${this.smallImg ? this.smallImg : imgsrc2})`)
				})
				this.handleLoginTxt()
			}
		},
		async onSubmit() {
			this.$refs.form.validate((valid) => {
				if (valid) {
					if (!this.hasreaded) {
						this.$message.warning('请勾选用户协议')
						return false
					}
					this.toLogin()
				} else {
					return false
				}
			})
		},
		async postAccountGetAuthCode() {
			const data = { account: this.formData.account, type: 1 }
			this.formData.authCode = null
			if (isPhone.test(this.formData.account) || isEmail.test(this.formData.account)) {
				if (this.Countdown > 0) return
				this.Countdown = 60
				this.setCountdown()
				this.$sensors.track('UM_Click_Verification_Code_Button')
				this.$api.usercenter.getAuthCodeCommon({ data })
			}
		},
		async toLogin() {
			localStorage.removeItem('organ')
			localStorage.removeItem('actor')
			localStorage.removeItem('branch')

			let method = this.activeName === 'password' ? 'postAccountLogin' : 'postAccountLoginByAuthCode'
            const data = { ...this.formData }
            if(this.isrenshe){//参培单位登录
                data.adminFlag = 1
            }
			data.password && (data.password = md5(data.password).toLowerCase())
			await this.$api.usercenter[method]({ data })
				.then(async (res) => {
					if (res.data) {
                        localStorage.setItem('token', res.data)
                        this.$store.commit('setToken',res.data)

						try {
                            if(this.isrenshe){
                                await this.getFindOrganList()
                            }else{
                                await this.getFindUserBranchList()
                            }
                            // await this.getFindUserBranchList()
                            // if(this.isrenshe){//参培单位管理员登录
                            //     await this.findTrainBranchType()
                            // }
						} catch (error) {
                            console.log('error-------------',error)
                            localStorage.removeItem('token')
                            this.$store.commit('setToken','')
							if (error) return
						}

						// this.getAccountUserInfo() //账号
						// this.getUserInfo() //不同机构下

						if (this.$route.query.name) {
							this.$router.push({
								path: this.$route.query.name + window.location.search,
							})
						} else {
                            const frontendConfig = localStorage.getItem('frontendConfig') ? JSON.parse(localStorage.getItem('frontendConfig')) : ''
                            let path = '/'

                            if (frontendConfig.key == 'homepage' && frontendConfig.value) {
                                path = '/' + frontendConfig.value
                            }

                            if(this.isrenshe){ //参培单位登录跳转
                                path = '/home'
                            }

							this.$router.push({
								path: path,
							})
						}
					} else {
						return res.data
					}
				})
				.catch(() => {
					return false
				})
        },
        getFindOrganList() {
			return this.$api.rensheUsercenter.getFindOrganList().then(async (res) => {
				if (res.data && res.data.length) {
                    await this.setUserOrgan(res.data)
				}else{
                    return Promise.reject(res.message)
                }
			})
		},
		async setUserOrgan(organList) {
            let organItem = organList.find((item) => {
                let organ = md5(this.saasId + '').toUpperCase()
                // console.log('======================',organ,item.organ)
                return item.organ === organ
			})
			if(organItem){
				localStorage.setItem('name', organItem?.name)
				localStorage.setItem('organ', organItem?.organ)
				localStorage.setItem('actor', organItem?.actor)
			}else{
				this.$message.error('当前用户尚未注册，没有登录权限！')
                return Promise.reject('当前用户尚未注册，没有登录权限！')
			}
			if (!organItem.status) {
                this.$message.error('当前租户已被禁用')
                return Promise.reject('当前租户已被禁用')
			}
			await this.getFindAdminBranchList()
		},
		getAccountUserInfo() {
			this.$api.usercenter.getAccountUserInfo({}).then((res) => {
				this.$store.commit('saveUserInfo', res.data)
				localStorage.setItem('userInfo', JSON.stringify(res.data))
			})
		},
		getUserInfo() {
			this.$api.usercenter.findUser().then((res) => {
				if (res.code == 0) {
					this.$store.commit('saveSubUserInfo', res.data)
					localStorage.setItem('subUserInfo', JSON.stringify(res.data))
				}
			})
		},
		getFindUserBranchList() {
			return this.$api.usercenter.getFindUserBranchList({}).then((res) => {
				if (res.data && res.data.length) {
					const branchList = res.data

					if (branchList.length) {
						localStorage.setItem('branchList', JSON.stringify(branchList))
						
						let currentBranch = null
						currentBranch = branchList[0]
						if (!this.isrenshe) {
                            // 登录域名指定的子机构
                            currentBranch = branchList.find((item) => {
                                let branch = md5(this.branchId + item.token).toUpperCase()
                                // console.log('======================',branch,item.branch)
                                
                                return item.branch === branch
                            })
                        }

                        if(currentBranch){
                            localStorage.setItem('code', currentBranch.code)
                            localStorage.setItem('organ', currentBranch.organ)
                            localStorage.setItem('actor', currentBranch.actor)
                            localStorage.setItem('branch', currentBranch.branch)
                            localStorage.setItem('branchName', currentBranch.name)
                            localStorage.setItem('userId', currentBranch.userId)
                            this.$sensors.login(currentBranch.code)

                            this.getAccountUserInfo() //账号
						    this.getUserInfo() //不同机构下
                        }else{
                            this.$message.error('暂未加入当前机构')
                            return Promise.reject('暂未加入当前机构')
                        }
					}
				} else {
					return Promise.reject(res.message)
				}
			})
        },
        getFindAdminBranchList(){
            return this.$api.rensheUsercenter.getFindAdminBranchList().then(async (res) => {
				if (res.data && res.data.length) {
					const branchList = res.data

					if (branchList.length) {
						localStorage.setItem('branchList', JSON.stringify(branchList))
						
						let currentBranch = branchList[0]
                        if(currentBranch){
                            localStorage.setItem('code', currentBranch.code)
                            localStorage.setItem('organ', currentBranch.organ)
                            localStorage.setItem('actor', currentBranch.actor)
                            localStorage.setItem('branch', currentBranch.branch)
                            localStorage.setItem('branchName', currentBranch.name)
                            localStorage.setItem('userId', currentBranch.userId)
                            localStorage.setItem('channel',1)//管理员

                            this.$store.commit('setBranchName',currentBranch.name)

                            await this.findTrainBranchType()
                            this.$sensors.login(currentBranch.code)
                        }
					}
				} else {
					return Promise.reject(res.message)
				}
			})
        },
        // 判断当前培训机构类型 0 教学资源合作机构 1 参培单位
        findTrainBranchType(){
            return this.$api.rensheUsercenter.findTrainBranchType({phone:this.formData.account}).then((res) => {
                if(res.success && res.data === 0){
                    this.$message.error('暂无权限登录')
                    return Promise.reject('暂无权限登录')
                }
                if(!res.success){
                    return Promise.reject(res.message)
                }

                this.getAccountUserInfo() //账号
            })
        },
		setCountdown() {
			this.CountdownInterval = setInterval(() => {
				this.Countdown--
				if (this.Countdown <= 0) {
					clearInterval(this.CountdownInterval)
				}
			}, 1000)
		},
		toRegister() {
			this.$router.push('/register')
		},
	},
}
/* eslint-disable no-new */
</script>

<style lang="stylus" scoped>
@import '../asset/css/login';

.tabs {
  background: #fff;
  font-size: 24px;
  color: #999;
  font-family: PingFangSC-Medium, PingFang SC;
  padding-left: 0;
  text-align: left;

  li {
    position: relative;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    height: 40px;
    margin-bottom: 0;

    &.highlight.yingji {
      border-bottom:none;
      color:#D63535;
    }
    &.highlight.wanghong {
      border-bottom:none;
      color:#AD6ADE;
    }
    &.highlight.shanghaijiaoda {
      border-bottom:none;
      color:#C51022;
    }
    &.highlight {
      border-bottom:none;
      color:#316FFF;
    }

    &.hastwo:first-child::after {
      position: absolute;
      right: -20px;
      top: 14px;
      content: '';
      display: inline-block;
      width: 1px;
      height: 15px;
      background: #E6E6E6;
    }
  }
}

.login {
  .login-btn {
    width: 190px;
    height: 36px;
    padding: 0;
    border-radius: 22px;
    background: #316fff;
    border: none;
  }

  /deep/ .el-button {
    color: #fff;
  }

  /deep/.el-button--primary >span {
    font-size: 14px;
  }

  /deep/ .el-form-item.is-error .el-input__inner, /deep/ .el-form-item .el-input__inner:focus {
    border-color: #D8D8D8;
    caret-color: #316fff;
  }

  /deep/ .el-button--info.is-disabled, /deep/ .el-button--info.is-disabled:active, /deep/ .el-button--info.is-disabled:focus, /deep/ .el-button--info.is-disabled:hover {
    background-color: #DEDDDE;
    border: #DEDDDE;
  }

  .forget {
    display: inline-block;
    margin-left: 20px;

    .fr {
      color: #5F6368;
    }
  }
}

.el-form-item {
  margin-top: 30px;
  margin-right: 0;
  // .sendCodeBtn.yingji:hover{
  //   background:#D63535 !important;
  // }
  // .sendCodeBtn.wanghong:hover{
  //   background:linear-gradient(147deg, #7D7EFF 0%, #FF48A7 100%) !important;
  // }
}
.read{
    margin-top 10px
    .agreement{
        color #0F3EAA
        cursor pointer
    }
}
.btn {
  display: flex;
  align-items: center;
  margin-top: 60px;

  .loginbtn {
    width: 190px;
    height: 36px;
    margin-right: 20px;
    padding: 0;
    border-radius: 22px;
    background: #316fff;
    border:none;
  }
  .loginbtn.yingji{
    background: #D63535;
  }
  .loginbtn.yingji:hover{
    background: #D63535 !important;
  }
  .loginbtn.shanghaijiaoda{
    background: #C51022;
  }
  .loginbtn.shanghaijiaoda:hover{
    background: #C51022 !important;
  }
  .loginbtn.wanghong{
    background: linear-gradient(147deg, #7D7EFF 0%, #FF48A7 100%);
  }
  .loginbtn.wanghong:hover{
    background: linear-gradient(147deg, #7D7EFF 0%, #FF48A7 100%) !important;
  }

  /deep/ .el-button--primary span {
    font-size: 14px;
  }
}

.footerTip {
  display: flex;
  margin-top: 20px;

  .tip {
    font-size: 12px;
    color: #5F6368;
  }

  .view {
    font-size: 12px;
    color: #1972ff;
  }
  .view.yingji{
    color: #D63535;
  }
  .view.wanghong{
    color: #AD6ADE;
  }
  .view.shanghaijiaoda{
    color: #C51022;
  }
}

.mt20.ct {
  font-size: 16px;
  color: #999999;
}
</style>
