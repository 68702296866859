<template>
	<div class="loginBox register-jz-page">
		<div class="login">
			<p class="login-left" />
			<div class="login-box">
				<template v-if="!isRegistSuccess">
					<ul class="tabs">
						<li
							:key="item.key"
							v-for="(item, index) in tabsList"
							@click="setActiveName(item.key)"
							:style="index === 0 ? 'margin-right:40px' : ''"
							:class="{ highlight: activeName === item.key, isHasOne: tabsList.length == 1 }"
						>
							{{ item.val }}
						</li>
					</ul>
					<div class="form">
						<el-form ref="form" :rules="rules" :model="formData">
							<el-form-item prop="account" ref="account">
								<el-input :placeholder="activeName == 'phone' ? '请输入手机号' : '请输入邮箱账号'" v-model="formData.account"> </el-input>
							</el-form-item>
							<el-form-item v-if="activeName === 'phone'" prop="authCode" ref="authCode">
								<el-input placeholder="请输入验证码" v-model="formData.authCode">
									<el-button slot="append" @click="postAccountGetAuthCode('phone')" round :style="sendCodeBtnStyle"
										>{{ 0 >= Countdown1 ? '获取验证码' : Countdown1 + 's' }}
									</el-button>
								</el-input>
							</el-form-item>
							<el-form-item v-else prop="authCode" ref="authCode">
								<el-input placeholder="请输入验证码" v-model="formData.authCode">
									<el-button slot="append" :style="sendCodeBtnStyle" round @click="postAccountGetAuthCode('mail')">
										{{ 0 >= Countdown2 ? '获取验证码' : Countdown2 + 's' }}
									</el-button>
								</el-input>
							</el-form-item>
							<el-form-item prop="password" ref="password">
								<el-input
                                    @blur="handleBlur(1)"
                                    :readonly="isReadonly1"
									@focus="handleFocus(1)"
									type="password"
									autocomplete="new-password"
									placeholder="请设置8-20位，数字、大小写字母、字符组合密码"
									v-model="formData.password"
								></el-input>
							</el-form-item>
							<el-form-item class="rePassword" prop="rePassword" ref="rePassword">
								<el-input
                                    @blur="handleBlur(2)"
                                    :readonly="isReadonly2"
									@focus="handleFocus(2)"
									placeholder="请再次输入登录密码"
									:type="passw"
									autocomplete="new-password"
									v-model="formData.rePassword"
								>
									<template slot="suffix">
										<span class="password pwd-icon" v-show="formData.rePassword && passw === 'password'" @click="showPassw">
											<svg-icon icon-class="pwd-yincang"></svg-icon>
										</span>
										<span class="password pwd-icon" v-show="formData.rePassword && passw === 'text'" @click="showPassw">
											<svg-icon icon-class="pwd-zhanshi"></svg-icon>
										</span>
									</template>
								</el-input>
							</el-form-item>
						</el-form>
					</div>
					<div class="read">
						<el-checkbox v-model="hasreaded">我已阅读并同意</el-checkbox>
						<span class="agreement" @click="goPage('service-agreement')">《用户协议》</span>及
						<span class="agreement" @click="goPage('privacy')">《法律声明及隐私政策》</span>
					</div>
					<div class="btn">
						<el-button class="regist" @click="onSubmit" :disabled="disabled()" :style="registBtnStyle">立即注册 </el-button>
						<span>已有账号？</span>
						<router-link class="toLogin" :to="{ name: 'login' }">立即登录 ></router-link>
					</div>
				</template>
				<template v-else>
					<div class="middle">
						<div class="registTitle">恭喜您，注册成功！</div>
						<div class="registTip">请妥善保管您的账户信息</div>
						<div class="toHome">正在跳转首页…{{ backCount }}s</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import $ from 'jquery'
import md5 from 'js-md5'
import config from '@/config/url'
import { isPhone, isEmail, passwordValidator } from '@/utils/validator'

export default {
	name: 'register',
	data() {
		const accountValidator = (rule, value, callback) => {
			if (isPhone.test(value) || isEmail.test(value)) {
				callback()
			} else {
				let message = ''
				if (this.activeName === 'phone') {
					message = this.formData.account ? '请输入正确的手机号' : '请输入手机号'
				} else {
					message = this.formData.account ? '请输入正确的邮箱账号' : '请输入邮箱账号'
				}
				callback(new Error(message))
			}
		}
		return {
            isReadonly1: true,
			isReadonly2: true, //避免弹出密码提示框
			passw: 'password',
			activeName: 'phone',
			tabsList: [
				{ key: 'phone', val: '手机号注册' },
				// { key: 'mail', val: '邮箱注册' },
			],
			Countdown1: 0,
			Countdown2: 0,
			rules: {
				account: [{ validator: accountValidator, trigger: 'blur' }],
				authCode: [
					{ required: true, message: '请输入验证码', trigger: 'blur' },
					{ min: 6, max: 6, message: '请输入六位验证码', trigger: 'blur' },
				],
				password: [
					{ required: true, message: '登录密码不能为空', trigger: 'blur' },
					{ validator: passwordValidator, trigger: 'blur' },
				],
				rePassword: [{ required: true, message: '登录密码不能为空', trigger: 'blur' }],
			},
			formData: {
				account: null,
				authCode: null,
				password: null,
				rePassword: null,
			},
			isRegistSuccess: false,
			hasreaded: false,
			backCount: 3,
			branchId: '', // 人社子机构id
            branchUcode: '', // 人社子机构ucode
		}
	},
	computed: {
		sendCodeBtnStyle() {
			let background = '#316fff'
			let CountdownType = this.activeName === 'phone' ? 'Countdown1' : 'Countdown2'
			return {
				background: 0 >= this[CountdownType] ? background : '#BFC8D9',
				border: 'none',
			}
		},
		registBtnStyle() {
			let background = '#316fff'
			return {
				background: this.disabled() ? '#BFC8D9' : background,
				border: 'none',
			}
		},
	},
	created() {
        // console.log('bendi=========================================')
        const saasId = localStorage.getItem('saasId')
		sessionStorage.clear()
		localStorage.clear()
		localStorage.setItem('saasId', saasId)
		let url = config.confUrl
		this.configuration(url)
	},
	mounted() {},
	methods: {
		//   隐私协议跳转
		goPage(url) {
			window.open(`${window.location.origin}/${url}`)
		},
		showPassw() {
			if (this.passw === 'password') {
				this.passw = 'text'
			} else {
				this.passw = 'password'
			}
		},
		disabled() {
			let result = false
			for (let key in this.formData) {
				if (!this.formData[key]) {
					result = true
					break
				}
			}
			if (!this.hasreaded) {
				result = true
			}
			return result
		},
		configuration(url) {
			this.$api.configure.configurationNew(url).then((res) => {
				if (res.data) {
					this.branchId = res.data.branchId
                    this.branchUcode = res.data.branchUcode
                    localStorage.setItem('branchId', res.data.branchId || '')
					localStorage.setItem('domain', JSON.stringify(res.data.domain) ? JSON.stringify(res.data.domain) : '')
                    localStorage.setItem('configurationArr', JSON.stringify(res.data.frontendConfig))
                    this.$store.commit('setTheme', res.data.theme || '')
					this.initConfig()
				}
			})
		},
		initConfig() {
			/* 
                手机号+验证码 is_allow_telephone_register 
                手机号+密码   is_allow_email_register 
            */
			let configurationArr = JSON.parse(localStorage.getItem('configurationArr'))
			if (configurationArr.length > 0) {
				configurationArr.forEach((item) => {
					switch (item.key) {
						case 'custom_login_background_img':
							this.bigImg = item.value
							break
						case 'custom_login_img':
							this.smallImg = item.value
							break
						case 'is_allow_telephone_register':
							if (item.value === 'false') {
								let index = this.tabsList.findIndex((item) => {
									return item.key === 'phone'
								})
								this.tabsList.splice(index, 1)
							}
							break
						case 'is_allow_email_register':
							if (item.value === 'false') {
								let index = this.tabsList.findIndex((item) => {
									return item.key === 'mail'
								})
								this.tabsList.splice(index, 1)
							}
							break
						default:
							break
					}
				})
				this.activeName = this.tabsList[0].key

				const imgsrc1 = 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/beijin.png'
				const imgsrc2 = 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/login/rectangle.png'
				this.$nextTick(() => {
					// 防止背景图闪现两次
					$('.loginBox').css('background-image', `url(${this.bigImg ? this.bigImg : imgsrc1})`)
					$('.login-left').css('background-image', `url(${this.smallImg ? this.smallImg : imgsrc2})`)
				})
			}
		},
		setActiveName(val) {
			if (val === this.activeName) return
			this.activeName = val
			this.$nextTick(() => {
				this.$refs.form.clearValidate()
			})
			// 重置表单
			this.formData = {
				account: null,
				authCode: null,
				password: null,
				rePassword: null,
			}
			// this.co
		},
		async onSubmit() {
			this.$refs.form.validate((valid) => {
				if (valid) {
					if (!this.hasreaded || !this.handleBlur()) return
					this.postAccountRegistByAuthCode()
				} else {
					return false
				}
			})
		},
		postAccountGetAuthCode(type) {
			let CountdownType = type === 'phone' ? 'Countdown1' : 'Countdown2'
			let data = { account: this.formData.account, adminFlag: 0, type: 2 }
			if (this[CountdownType] > 0) return
			this.formData.authCode = null
			if (type === 'phone') {
				if (isPhone.test(this.formData.account)) {
					this.Countdown1 = 60
					this.setCountdown(type)
					this.$sensors.track('UM_Click_Verification_Code_Button')
					this.$api.usercenter.getAuthCodeCommon({ data })
				}
			} else {
				if (isEmail.test(this.formData.account)) {
					this.Countdown2 = 60
					this.setCountdown(type)
					this.$sensors.track('UM_Click_Verification_Code_Button')
					this.$api.usercenter.getAuthCodeCommon({ data })
				}
			}
		},
		async postAccountRegistByAuthCode() {
			const data = {
				...this.formData,
				branchUcode: this.branchUcode,
			}
			await this.$api.rensheUsercenter.postAccountRegister(data).then((res) => {
				if (res.success) {
					this.$message.success('注册成功')
                    this.isRegistSuccess = true
                    localStorage.setItem('token', res.data)
                    this.$store.commit('setToken',res.data)

					this.timer = setInterval(() => {
						this.backCount--
						if (this.backCount <= 0) {
							clearTimeout(this.timer)
							this.toSkip()
						}
					}, 1000)
				}
			})
		},
		async toSkip() {
			try {
				await this.getFindUserBranchList()
			} catch (error) {
                localStorage.removeItem('token')
                this.$store.commit('setToken','')
				if (error) return
			}

			this.getAccountUserInfo() //账号
			this.getUserInfo() //不同机构下

			if (this.$route.query.name) {
				this.$router.push({
					path: this.$route.query.name + window.location.search,
				})
			} else {
				const frontendConfig = localStorage.getItem('frontendConfig') ? JSON.parse(localStorage.getItem('frontendConfig')) : ''
				let path = '/distributionHomePage'

				if (frontendConfig.key == 'homepage' && frontendConfig.value) {
					path = '/' + frontendConfig.value
				}

				this.$router.push({
					path: path,
				})
			}
		},
		getAccountUserInfo() {
			this.$api.usercenter.getAccountUserInfo({}).then((res) => {
				this.$store.commit('saveUserInfo', res.data)
				localStorage.setItem('userInfo', JSON.stringify(res.data))
			})
		},
		getUserInfo() {
			this.$api.usercenter.findUser().then((res) => {
				if (res.code == 0) {
					this.$store.commit('saveSubUserInfo', res.data)
					localStorage.setItem('subUserInfo', JSON.stringify(res.data))
				}
			})
		},
		getFindUserBranchList() {
			return this.$api.usercenter.getFindUserBranchList({}).then((res) => {
				if (res.data && res.data.length) {
					const branchList = res.data

					if (branchList.length) {
						localStorage.setItem('branchList', JSON.stringify(branchList))

						// 登录域名指定的子机构
						let currentBranch = branchList.find((item) => {
                            let branch = md5(this.branchId + item.token).toUpperCase()
                            return branch === item.branch
                        }) 

						if (currentBranch) {
							localStorage.setItem('code', currentBranch.code)
							localStorage.setItem('organ', currentBranch.organ)
							localStorage.setItem('actor', currentBranch.actor)
							localStorage.setItem('branch', currentBranch.branch)
							localStorage.setItem('branchName', currentBranch.name)
							localStorage.setItem('userId', currentBranch.userId)
							this.$sensors.login(currentBranch.code)
						} else {
							this.$message.error('暂未加入当前机构')
							return Promise.reject('暂未加入当前机构')
						}
					}
				} else {
					return Promise.reject(res.data.message)
				}
			})
		},
		setCountdown(type) {
			let CountdownType = type === 'phone' ? 'Countdown1' : 'Countdown2'
			let Countdown = null
			Countdown = setInterval(() => {
				this[CountdownType]--
				if (this[CountdownType] <= 0) {
					clearInterval(Countdown)
				}
			}, 1000)
		},
        handleFocus(val) {
			setTimeout(() => {
				if (val === 1) {
					this.isReadonly1 = false
				} else {
					this.isReadonly2 = false
				}
			})
		},
        handleBlur(val) {
			if (val === 1) {
				this.isReadonly1 = true
			} else {
				this.isReadonly2 = true
			}
			let result = true
			if (this.formData.password && this.formData.rePassword) {
				if (this.formData.password !== this.formData.rePassword) {
					this.$message.error('两次密码输入不一致')
					result = false
				}
			}
			return result
		}
	},
}
</script>

<style lang="stylus" scoped>
@import '../asset/css/login';
/deep/ .el-form-item
  margin-top 30px

.tabs
  background #fff
  font-size 24px
  color #999
  font-family: PingFangSC-Medium, PingFang SC;
  padding-left 0
  text-align: left
  li
    position relative
    cursor: pointer
    display inline-block
    font-size: 20px;
    text-align center
    line-height 40px
    height 40px
    margin-bottom 30px
    &:first-child::after
      position absolute
      right -20px
      top 14px
      content ''
      display inline-block
      width 1px
      height 15px
      background #E6E6E6

    &.highlight {
      border-bottom:none;
      color:#316FFF;
    }
    &.highlight.yingji {
      border-bottom:none;
      color:#D63535;
    }
    &.highlight.shanghaijiaoda {
      border-bottom:none;
      color:#D63535;
    }
    &.highlight.wanghong {
      border-bottom:none;
      color:#AD6ADE;
    }

    &.isHasOne:first-child::after
      display none
.form
  .rePassword
    /deep/ .el-input__suffix-inner
      >img
        width 22px
        height 22px
  .pwd-icon{
    font-size:22px;
   }

.read
  margin 30px 0 20px 0
  .agreement
    color #0F3EAA
    cursor pointer
  .agreement.yingji{
    color: #D63535;
  }
  .agreement.wanghong{
    color: #AD6ADE;
  }
  .agreement.shanghaijiaoda{
    color: #C51022;
  }
.read.yingji
  /deep/ .el-checkbox__input.is-checked+.el-checkbox__label
    color #D63535
  /deep/ .el-checkbox__input.is-focus .el-checkbox__inner
    border-color #D63535
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner
    background-color #D63535
    border-color #D63535
  .agreement
    color #D63535

.read.shanghaijiaoda
  /deep/ .el-checkbox__input.is-checked+.el-checkbox__label
    color #C51022;
  /deep/ .el-checkbox__input.is-focus .el-checkbox__inner
    border-color #C51022;
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner
    background-color #C51022;
    border-color #C51022;
  .agreement
    color #C51022;

.read.wanghong
  /deep/ .el-checkbox__input.is-checked+.el-checkbox__label
    color #AD6ADE
  /deep/ .el-checkbox__input.is-focus .el-checkbox__inner
    border-color #AD6ADE
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner
    background-color #AD6ADE
    border-color #AD6ADE
  .agreement
    color #AD6ADE
.btn{
  display flex
  align-items center
  .regist{
    width 190px
    height 36px
    margin-right 20px
    padding 0
    border-radius 22px
    background #316fff
    /deep/ span{
      color #fff
    }
  }
  .regist.is-disabled:hover{
    background #BFC8D9 !important
  }
  .toLogin {
    font-size: 12px;
    color: #1972ff;
  }
  .toLogin.yingji{
    color: #D63535;
  }
  .toLogin.shanghaijiaoda{
    color: #C51022;
  }
  .toLogin.wanghong{
    color: #AD6ADE;
  }

  /deep/ .el-button--primary span{
    font-size 14px
  }
}
.middle
  margin-top 108px
  margin-left 10px
  .registTitle
    font-size 30px
    font-weight 600
    color #316FFF
  .registTip
    margin-top 12px
    font-size 16px
    color #666666
  .toHome
    margin-top 56px
    font-size 14px
    color #316FFF
.readdialog {
  /deep/ .el-dialog {
    height: 800px;
  }

  /deep/ .el-dialog__header {
    padding: 20px;
    font-size: 16px;
    background: #F8FAFF;
    font-weight: 600;
    border-radius 20px 20px 0 0
  }

  /deep/ .el-dialog__footer {
    background: #F8FAFF;
    padding: 20px;
    border-radius 0 0 20px 20px
  }

  /deep/ .el-dialog__title{
    font-size 22px
  }

  /deep/ .el-dialog__body{
    padding 0 20px
  }

  /deep/ .el-button{
    padding: 0px 20px;
    border-radius: 22px;
    width: 158px;
    height: 36px;
  }

  .privacy-box{
    height 660px
    margin-bottom 20px
    overflow auto
  }

  .privacy-box h1, .privacy-box h2 {
    text-align: center;
    margin 20px 0
  }

  .privacy-box h2 {
    text-align: left;
  }

  .privacy-box p {
    color: #666;
    text-align: justify;
    font-size 16px
  }

  .privacy-box .yinyan {
    text-align: center;
    margin-bottom 20px
  }

  .privacy-box .title2 {
    font-weight: bold;
    color: #000;
    text-indent: 0px;
    margin 10px 0 2px 0
  }
}
</style>
